<template>
  <component :is="layout">
    <router-view />
  </component>
</template>
<script lang="ts" setup>
  import { computed } from 'vue'
  import { useRouter } from 'vue-router'

  const defaultLayout = 'default'

  const { currentRoute } = useRouter()

  const layout = computed(
    () => `${currentRoute.value.meta.layout || defaultLayout}-layout`
  )
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap');
  * {
    font-family: 'Manrope', 'sans-serif';
  }
</style>

<style>
  .c-toast-container--top {
    right: 0 !important;
  }
  .c-toast--success {
    background-color: #17b288 !important;
  }
  .c-toast--error {
    background-color: #f03e3e !important;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    border-radius: 5px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #8d8d8d;
  }
</style>
